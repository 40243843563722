'use client';

import { DEFAULT_IMAGE_PATH } from '@web/constants/general';
import React from 'react';
import Image from 'next/image';

type IBannerProps = {
  url: string;
  alt: string;
};

export const ImageDisplay = ({ url, alt }: IBannerProps) => {
  return (
    <Image
      src={url || DEFAULT_IMAGE_PATH}
      alt={alt}
      fill
      sizes="(min-width: 200px)"
      style={{
        objectFit: 'cover',
      }}
    />
  );
};

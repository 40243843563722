export const DEFAULT_IMAGE_PATH = '/images/default.png';
export const PLACEHOLDER_PATH = '/images/img-loading.png';
export const BU_BANNER_PATH = '/images/banner-create-bu.png';
export const ADMIN_EMAIL = process.env.NEXT_PUBLIC_ADMIN_EMAIL;
export const MAX_BU_ADMIN_ACCOUNT = 99999;
export const MAX_BU_NORMAL_ACCOUNT = 4;
export const GET_START_PATH = '/images/get-start.png';
export const CREATE_BU_02_PATH = '/images/create-bu-02.png';
export const CREATE_BU_PATH = '/images/create-bu.png';
export const GOOGLE_01 = '/images/google-01.png';
export const GOOGLE_02 = '/images/google-02.png';
export const GOOGLE_03 = '/images/google-03.png';
export const GOOGLE_04 = '/images/google-04.png';
export const DOG_IN_FLOWER_POT = '/images/dog-in-flower-pot.svg';
export const NOT_FOUND = '/images/not-found.png';
export const LINE_QR = '/images/line/l.png';
export const POLICY = '/doc/PetcationPrivacyPolicy.html';
export const MAX_GET_BU_LIMIT = 50;
